
import TableList from "./TableList.vue";
import Buttons from "../../Commons/Buttons.vue";
import SnackBar from "../../Commons/SnackBar.vue";
import Vue from "vue";
import { User, UserFilters } from "../../../../interfaces/user";
import {
	SortingOption,
	Paginated,
	ResultPaginate,
} from "../../../../interfaces/paginated";
import { isEmpty, isNull, isUndefined } from "lodash";
import ParamService from "../../../../services/params-service";
import { mapGetters, mapActions } from "vuex";
import {
	prepareSelectedData,
	prepareTableData,
	prepareTableContent,
	preparedColumnDefsGrid
} from "../../../../utils/CustomizeViewData";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import GridTable from "@/components/Header/Tables/GridTable.vue";
// @ts-ignore
import ActionsTable from "@/components/Header/Tables/ActionsTable.vue"
// @ts-ignore
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue"
// @ts-ignore
import ActiveFieldTable from "@/components/Header/Tables/ActiveFieldTable.vue"
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
//@ts-ignore
import { getPermisionUser } from "@/utils/permissionResolve.ts";
//@ts-ignore
import NotPermission from "@/views/Errors/not_permission.vue";

export default Vue.extend({
	name: "UserList",
	props: {},
	components: { 
		TableList, 
		Buttons, 
		SnackBar,
		GridTable,
		ActionsTable,
		FilterGridAG,
		ActiveFieldTable,
		NotPermission
	},
	data: function () {
		return {
			title: "List",
			paginated: {
				page: 1,
				limit: 25,
			} as Paginated,
			filters: {},
			options: getDefaultSortingOption("id"),
			table: {
				headers: [],
				items: [],
			},
			fields: {},
			columnRestored: false,
			context: null,
			selectionRows: "single"
			
		};
	},
	created() {
		this.$nextTick(async () => {});
	},
	async mounted() {
		await this.getPaginated();
		this.context = { componentParent: this };
	},
	computed: {
		...mapGetters("customize_view", [
			"getFieldByView",
			"getSelectedByView",
		]),
		...mapGetters("internationalization", ["getLanguage"]),

		...mapGetters("profile", ["getAbility"]),

		getFilters() {
			return this.filters;
		},
		getResultPaginate(): ResultPaginate {
			return this.$store.state.user.result_paginate;
		},
		getUsers(): User[] {
			const result: ResultPaginate = this.getResultPaginate;
			if (
				isUndefined(result) ||
				isNull(result) ||
				isUndefined(result.data) ||
				isNull(result.data)
			) {
				return [];
			}
			return result.data;
		},
		getSizeUsers() {
			return this.$t("show.users", {
				total: this.getUsers.length,
			});
		},

		getConfig() {
			return {
				paginated: this.paginated,
				filters: this.filters,
				options: this.options,
				fields: this.fields,
			};
		},

		getConfigColumnDef(){
			return {
				context: this.context,
				eventActive: false,
				entityName: "User",
				redirect: "UserEdit",
				edit: true,
				flex: 0,
				minWidthActions: 80,
				maxWidthActions: 80,
				permission: this.getPermission
			};
		},

		getColumnsDef(){
			return preparedColumnDefsGrid(this.table.headers, this.getConfigColumnDef);
        },

        gerRowsData(){
            if(isEmpty(this.table.items)){
                return [];
            }else{
				return this.table.items;
			}
        },

		canList(){
			return this.getAbility.can(this.getPermission.actions.index, this.getPermission.subject);
		},

		getPermission(){
			return getPermisionUser();
		}
	},
	methods: {
		/** Begin Customize View **/
		...mapActions("loading", ["setLoadingData"]),

		...mapActions("customize_view", [
			"setFields",
			"saveSelected",
			"saveColumns",
			"getTableDataSelected",
		]),

		hasAdvertiserID() {
			return this.$route.query.advertiser_id > 0;
		},
		getAdvertiserID() {
			return this.$route.query.advertiser_id;
		},

		async handleSave(event: any) {
			await this.saveSelected(event);
			await this.saveColumns({ event, columns: this.table.headers });
			await this.getTableData(event);
		},

		async handleUpdateColumns(event: any) {
			await this.saveSelected(event);
		},

		async handleUpdateSizeColumns(params: any) {
			params.forEach(element => {
				let col = this.table.headers.filter(c => c.value == element.col);
				col[0].reactive_width = element.actualWidth;
				col[0].width = String(element.actualWidth) + "px";
			});
			let event = {
				view: params.entity
			};
			await this.saveColumns({ event, columns: this.table.headers });
		},

		async updateItemsTable(){
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);

			this.fields = await prepareSelectedData(selected);

			this.table.items = await prepareTableContent(this.fields, this.getUsers);
			this.$forceUpdate();
		},

		async getTableData(
			event: { view: string; isReset: Boolean } = {
				view: "",
				isReset: true,
			}
		) {
			const { selected, fields } = await this.getTableDataSelected({
				route: this.$route?.name,
				entity: this.getUsers,
			});

			this.fields = fields;

			const headers: Array<any> = selected.columns;
			const columns: Array<any> = await this.getColumns();

			this.table = await prepareTableData({
				columns: columns,
				fields: this.fields,
				entity: "users",
				entities: this.getUsers,
				isReset: event?.isReset,
			});

			if (isEmpty(headers) || event?.isReset) {
				await this.saveColumns({ event, columns: this.table.headers });
				//this.columnRestored = event?.isReset;
			}

			this.$forceUpdate();
		},

		async getColumns() {
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);
			const columns: Array<any> = selected.columns;
			return columns ?? [];
		},

		/** End Customize View **/

		async getPaginated(uTable: boolean = true) {
			if(this.canList){
				this.setLoadingData(TypeLoading.loading)
				await this.$store.dispatch(
					"user/paginated",
					await ParamService.getParams(
						this.paginated,
						this.filters,
						this.options
					)
				);
				if(uTable){
					await this.getTableData({ view: this.$route?.name });
				}else{
					await this.updateItemsTable();
				}
				await this.setLoadingData();
			}
		},
		updatePaginate(data: any) {
			this.paginated.page = data;
		},
		setFilter(params: { key: string | number; value: any }) {
			this.filters = {};
			this.filters[params.key] =
				typeof params.value !== "undefined" ? params.value : "";
		},
		async updateParams(params: {
			filters: UserFilters;
			options: SortingOption;
		}, uTable: boolean = true) {
			this.filters = params.filters;
			this.options = params.options;
			this.updatePaginate(1);
			await this.getPaginated(uTable);
		},
		async selectedLimit(limit: number) {
			this.paginated.limit = limit;
			this.updatePaginate(1);
			await this.getPaginated(false);
		},
		async selectedOption(params: { options: SortingOption; filter: any }) {
			this.setFilter({ key: params.options.sort, value: params.filter });
			this.updatePaginate(1);
			await this.updateParams({
				filters: this.filters,
				options: params.options,
			}, false);
		},
		async updateCurrentPage(page) {
			this.updatePaginate(page);
			await this.getPaginated(false);
		},
		async removeOption() {
			this.filters = {};
			this.options = getDefaultSortingOption();
			this.updatePaginate(1);
			await this.getPaginated(false);
		},
		async handleResetColumns() {
			await this.getTableData({ view: this.$route?.name, isReset: true });
		},
	},
	watch: {
		"paginated.page"(val, old) {
			if (val !== old) {
				this.getPaginated(false);
			}
		},
		async getLanguage(lang) {
			await this.handleResetColumns();
			this.$forceUpdate();
		},
	},
});
