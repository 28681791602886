
import Vue from "vue";
// @ts-ignore
import i18n from "@/plugins/i18n";
import { isEmpty, isNull } from "lodash";
import Filterable from "../../../../components/Header/Tables/Filterable.vue";
import { SortingOption } from "../../../../interfaces/paginated";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";

export default Vue.extend({
	name: "TableList",
	props: {
		current_page: {
			type: Number,
		},
		next_page_url: {
			type: String,
		},
		path: {
			type: String,
		},
		per_page: {
			type: Number,
		},
		prev_page_url: {
			type: String,
		},
		to: {
			type: Number,
		},
		total: {
			type: Number,
		},
		headers: {
			type: Array,
			default: [],
		},
		items: {
			type: Array,
			default: [],
		},
		options: {
			type: Object,
			default: function () {
				return {};
			},
		},
		filters: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	components: { Filterable, IconBase },
	data: () => ({}),
	created() {},
	mounted() {},

	computed: {
		currentPage: {
			set(val) {
				this.$emit("update-current-page", val);
			},
			get() {
				return this.current_page;
			},
		},
		getLength() {
			return Math.ceil(this.total / this.per_page);
		},
		perPage() {
			return !isNaN(this.per_page) && isEmpty(this.per_page)
				? this.per_page
				: 25;
		},
	},

	methods: {
		getColor(active: Boolean) {
			return active ? "green--text" : "red--text";
		},
		getActiveText(active: Boolean) {
			return active
				? i18n.t("common.fields.active")
				: i18n.t("common.fields.inactive");
		},
		updatePaginate(data: Number) {
			this.$emit("update-paginate", data);
		},
		selectedOption(params: { options: SortingOption; filter: any }) {
			this.$emit("selected-option", {
				options: params.options,
				filter: params.filter,
			});
		},
		rowClick(value) {
			if (!isNull(value.id)) {
				this.$router.push({ name: "UserEdit", params: { id: value.id } });
			}
		},
		removeOption() {
			this.$emit("remove-option");
		},
	},
});
